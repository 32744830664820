import { PATH_SUB_DISTRIBUTOR } from './paths';
import React, { lazy } from 'react';

import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardSubDistributorLayout from '../layouts/DashboardSubDistributorLayout';


const AppSubDistributorRoutes = {
  path: PATH_SUB_DISTRIBUTOR.root,
  layout: DashboardSubDistributorLayout,
  guard: AuthProtect,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    // DASHBOARD
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.general.root,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/DashboardAppView'))
    },

    // OFFER
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.offers.root,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/offers/Offers'))
    },
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.offers.detail,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/offers/detail/OfferView'))
    },
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.offers.offerSuccess,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/offers/detail/OfferSuccess'))
    },
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.offers.manageRating,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/offers/detail/ManageRating'))
    },  
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.offers.manageRecommendedCartonQty,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/offers/detail/ManageRecommendedCartonQty'))
    },

    // PRE ORDER
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.preorder.root,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/pre-order/PreOrder'))
    },
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.preorder.detail.view,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/pre-order/PreOrderView'))
    },
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.preorder.detail.viewOrderDetail,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/pre-order/ViewOrderDetail'))
    },

    // CLOSED OFFERS
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.closedOffers.root,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/closed-offers/ClosedOffers'))
    },
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.closedOffers.view,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/closed-offers/detail/ClosedOfferView'))
    },
    
    // PAYMENT
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.payment.root,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/payment/Payment'))
    },
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.payment.view,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/payment/PaymentView'))
    },

    // ORDER
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.order,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/invoice/Invoice'))
    },
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.orderDetail.view,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/invoice/detail/InvoiceView2'))
    },

    // NOTIFICATION
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.notification.root,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/notifications/Notifications'))
    },
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.notification.view,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/notifications/detail/NotificationView'))
    },

    // CREDIT NOTE
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.creditNote,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/credit-note/CreditNote'))
    },
    {
      exact: true,
      path: PATH_SUB_DISTRIBUTOR.creditNoteDetail.view,
      component: lazy(() => import('src/views/AdminSubDistributorGroup/credit-note/detail/CreditNoteView'))
    }
  ]
}

export default AppSubDistributorRoutes