import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';

const server_url = process.env.REACT_APP_SERVER_URL
// ----------------------------------------------------------------------

const initialState = {
  notifications: [],
  totalUnreadNotifications: 0
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      const notifications = action.payload;
      state.notifications = notifications;
    },

    getTotalUnreadNotifications(state, action) {
      state.isLoading = false;
      state.totalUnreadNotifications = action.payload;
    },

    // MARK ALL AS READ
    markAllAsRead(state) {
      const markAll = state.notifications.map((notification) => {
        return {
          ...notification,
          isUnRead: false
        };
      });

      state.notifications = markAll;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { markAllAsRead } = slice.actions;

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/notifications');
      dispatch(
        slice.actions.getNotificationsSuccess(response.data.notifications)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function get10LatestNotifications(token, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = queryString.stringify(params);
      const response = await axios.get(`${server_url}/api/notification/distributor/get10LatestNotifications?${query}`, {
        headers: { authorization: `Bearer ${token}` }
      });
      dispatch(
        slice.actions.getNotificationsSuccess(response.data.notifications)
      );
      dispatch(
        slice.actions.getTotalUnreadNotifications(response.data.totalUnreadNotifications)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.response));
    }
  };
}
