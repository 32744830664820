import { PATH_ADMIN, PATH_DOCS, PATH_SALES } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardAdminLayout from 'src/layouts/DashboardAdminLayout';

// ----------------------------------------------------------------------

const AppAdminRoutes = {
  path: PATH_ADMIN.root,
  guard: AuthProtect,
  layout: DashboardAdminLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    // DASHBOARD
    {
      exact: true,
      path: PATH_ADMIN.general.dashboard,
      component: lazy(() => import('src/views/AdminDashboardGroup/dashboard/DashboardAppView'))
    },

    // OFFERS
    {
      exact: true,
      path: PATH_ADMIN.general.offers,
      component: lazy(() => import('src/views/AdminDashboardGroup/offers/Offers'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addOffers,
      component: lazy(() => import('src/views/AdminDashboardGroup/offers/add-offers/AddOffers2'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.offerDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/offers/details/OfferView'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.offerDetails.product,
      component: lazy(() => import('src/views/AdminDashboardGroup/offers/details/OfferViewProduct'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addOffersDetail.addProduct,
      component: lazy(() => import('src/views/AdminDashboardGroup/offers/add-product/AddProduct'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.editOffers,
      component: lazy(() => import('src/views/AdminDashboardGroup/offers/edit-offers/EditOffers'))
    },

    // PRODUCTS
    {
      exact: true,
      path: PATH_ADMIN.general.products,
      component: lazy(() => import('src/views/AdminDashboardGroup/products/Products'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addProduct,
      component: lazy(() => import('src/views/AdminDashboardGroup/products/add-products/AddProduct'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.productDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/products/details/ProductView'))
    },

    // PRE ORDER
    {
      exact: true,
      path: PATH_ADMIN.general.preOrder,
      component: lazy(() => import('src/views/AdminDashboardGroup/pre-order/PreOrder'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addPreOrder,
      component: lazy(() => import('src/views/AdminDashboardGroup/pre-order/add-pre-order/AddPreOrder'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.preOrderDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/pre-order/details/PreOrderView'))
    },

    // ORDER
    {
      exact: true,
      path: PATH_ADMIN.general.order,
      component: lazy(() => import('src/views/AdminDashboardGroup/invoice/Invoice'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addOrder,
      component: lazy(() => import('src/views/AdminDashboardGroup/invoice/AddInvoice'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.orderDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/invoice/details/ViewInvoice'))
    },

    // PAYMENT
    {
      exact: true,
      path: PATH_ADMIN.general.payment,
      component: lazy(() => import('src/views/AdminDashboardGroup/payment/Payment'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addPayment,
      component: lazy(() => import('src/views/AdminDashboardGroup/payment/add-payment/AddPayment'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.paymentDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/payment/PaymentView'))
    },

    // SHIPMENT
    {
      exact: true,
      path: PATH_ADMIN.general.shipment,
      component: lazy(() => import('src/views/AdminDashboardGroup/shipment/Shipment'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addShipment,
      component: lazy(() => import('src/views/AdminDashboardGroup/shipment/AddShipment'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.shipmentDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/shipment/details/ShipmentView'))
    },

    // RETAILER
    {
      exact: true,
      path: PATH_ADMIN.general.retailer,
      component: lazy(() => import('src/views/AdminDashboardGroup/retailer/Retailers'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addRetailer,
      component: lazy(() => import('src/views/AdminDashboardGroup/retailer/add-retailer/AddRetailer'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.retailerDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/retailer/view-retailer/ViewRetailer'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.retailerDetails.edit,
      component: lazy(() => import('src/views/AdminDashboardGroup/retailer/edit-retailer/EditRetailer'))
    },

    // SUPPLIER
    {
      exact: true,
      path: PATH_ADMIN.general.supplier,
      component: lazy(() => import('src/views/AdminDashboardGroup/supplier/Supplier'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addSupplier,
      component: lazy(() => import('src/views/AdminDashboardGroup/supplier/AddSupplier'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.supplierDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/supplier/details/SupplierView'))
    },

    // REPORTS,
    {
      exact: true,
      path: PATH_ADMIN.general.reports,
      component: lazy(() => import('src/views/AdminDashboardGroup/reports/Reports'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.reportsProductSalesSummary,
      component: lazy(() => import('src/views/AdminDashboardGroup/reports/sales/ReportsProductSalesSummary'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.reportsProductSalesDetailed,
      component: lazy(() => import('src/views/AdminDashboardGroup/reports/sales/ReportsProductSalesDetailed'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.reportsProductPriceList,
      component: lazy(() => import('src/views/AdminDashboardGroup/reports/sales/ReportsProductPriceList'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.reportsSalesOrderSummary,
      component: lazy(() => import('src/views/AdminDashboardGroup/reports/sales/ReportsSalesOrderSummary'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.reportsCustomerOrderSummary,
      component: lazy(() => import('src/views/AdminDashboardGroup/reports/sales/ReportsCustomerOrderSummary'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.reportsVendorList,
      component: lazy(() => import('src/views/AdminDashboardGroup/reports/purchasing/ReportsVendorList'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.reportsPurchaseOrderSummary,
      component: lazy(() => import('src/views/AdminDashboardGroup/reports/purchasing/ReportsPurchaseOrderSummary'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.reportsPurchaseOrderDetails,
      component: lazy(() => import('src/views/AdminDashboardGroup/reports/purchasing/ReportsPurchaseOrderDetails'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.reportsVendorProductList,
      component: lazy(() => import('src/views/AdminDashboardGroup/reports/purchasing/ReportsVendorProductList'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.reportsCustomerPaymentSummary,
      component: lazy(() => import('src/views/AdminDashboardGroup/reports/payment/ReportsCustomerPaymentSummary'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.reportsCustomerPaymentByOrder,
      component: lazy(() => import('src/views/AdminDashboardGroup/reports/payment/ReportsCustomerPaymentByOrder'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.reportsCustomerPaymentDetails,
      component: lazy(() => import('src/views/AdminDashboardGroup/reports/payment/ReportsCustomerPaymentDetails'))
    },

    // COSTING
    {
      exact: true,
      path: PATH_ADMIN.general.costingView,
      component: lazy(() => import('src/views/AdminDashboardGroup/costing/CostingView'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.costingEdit,
      component: lazy(() => import('src/views/AdminDashboardGroup/costing/CostingEdit'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.costingNew,
      component: lazy(() => import('src/views/AdminDashboardGroup/costing/CostingNew'))
    },

    // USER
    {
      exact: true,
      path: PATH_ADMIN.general.user,
      component: lazy(() => import('src/views/AdminDashboardGroup/user/User'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addUser,
      component: lazy(() => import('src/views/AdminDashboardGroup/user/AddUser'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.userDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/user/details/UserView'))
    },

    // DISTRIBUTOR
    {
      exact: true,
      path: PATH_ADMIN.general.distributor,
      component: lazy(() => import('src/views/AdminDashboardGroup/distributor/Distributor'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addDistributor,
      component: lazy(() => import('src/views/AdminDashboardGroup/distributor/AddDistributor'))
    },

    // PURCHASE ORDER
    {
      exact: true,
      path: PATH_ADMIN.general.purchaseOrder,
      component: lazy(() => import('src/views/AdminDashboardGroup/purchase-order/PurchaseOrder'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addPurchaseOrder,
      component: lazy(() => import('src/views/AdminDashboardGroup/purchase-order/add-purchase-order/AddPurchaseOrder'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.purchaseOrderDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/purchase-order/details/PurchaseOrder'))
    },

    // EMAIL
    {
      exact: true,
      path: PATH_ADMIN.general.email,
      component: lazy(() => import('src/views/AdminDashboardGroup/email/EmailView'))
    },


    // VERSION 3 MODULES
    // ORDER - SALES
    {
      exact: true,
      path: PATH_ADMIN.version3.orderSales,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/order-sales/Order'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.addOrderSales,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/order-sales/AddOrder'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.orderSalesDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/order-sales/details/OrderView'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.orderSalesDetails.edit,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/order-sales/details/OrderEdit'))
    },

    // ORDER - SALES MANAGER
    {
      exact: true,
      path: PATH_ADMIN.version3.orderSalesManager,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/order-sales-manager/Order'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.addOrderSalesManager,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/order-sales-manager/AddOrder'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.orderSalesManagerDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/order-sales-manager/details/OrderView'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.orderSalesManagerDetails.edit,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/order-sales-manager/details/OrderEdit'))
    },

    // ORDER - FINANCE
    {
      exact: true,
      path: PATH_ADMIN.version3.orderFinance,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/order-finance/Order'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.orderFinanceDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/order-finance/details/OrderEdit'))
    },


    // PAYMENT - FINANCE
    {
      exact: true,
      path: PATH_ADMIN.version3.paymentFinance,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/payment-finance/Payment'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.paymentFinanceDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/payment-finance/details/PaymentView'))
    },

    // INVOICE - FINANCE
    {
      exact: true,
      path: PATH_ADMIN.version3.invoiceFinance,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/invoices-finance/Invoice'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.invoiceFinanceDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/invoices-finance/details/InvoiceView'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.invoiceFinanceDetails.edit,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/invoices-finance/details/InvoiceEdit'))
    },

    // INVOICE - FINANCE MANAGER
    {
      exact: true,
      path: PATH_ADMIN.version3.invoiceFinanceManager,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/invoices-finance-manager/Invoice'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.invoiceFinanceManagerDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/invoices-finance-manager/details/InvoiceView'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.invoiceFinanceManagerDetails.edit,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/invoices-finance-manager/details/InvoiceEdit'))
    },

    // INVOICE - SALES
    {
      exact: true,
      path: PATH_ADMIN.version3.invoiceSales,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/invoices-sales/Invoice'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.invoiceSalesDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/invoices-sales/InvoiceView'))
    },

    // TRACKING - SALES
    {
      exact: true,
      path: PATH_ADMIN.version3.trackingSales,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/tracking-sales/Tracking'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.trackingSalesDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/tracking-sales/TrackingView'))
    },

    // TRACKING - LOGISTIC
    {
      exact: true,
      path: PATH_ADMIN.version3.trackingLogistic,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/tracking-logistic/Tracking'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.trackingLogisticDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/tracking-logistic/TrackingView'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.trackingLogisticDetails.edit,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/tracking-logistic/TrackingEdit'))
    },

    // INBOUND - LOGISTIC
    {
      exact: true,
      path: PATH_ADMIN.version3.inboundLogistic,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/inbound-logistic/Inbound'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.addInboundLogistic,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/inbound-logistic/AddInbound'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.inboundLogisticDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/inbound-logistic/details/InboundView'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.inboundLogisticDetails.edit,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/inbound-logistic/details/InboundEdit'))
    },

    // INBOUND - SALES
    {
      exact: true,
      path: PATH_ADMIN.version3.inboundSales,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/inbound-sales/Inbound'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.inboundSalesView,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/inbound-sales/InboundView'))
    },

    // IMPORT - FINANCE
    {
      exact: true,
      path: PATH_ADMIN.version3.importFinance,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/import-finance/Import'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.importFinanceDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/import-finance/ImportView'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.importFinanceDetails.edit,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/import-finance/ImportEdit'))
    },

    // OFFER - SALES
    {
      exact: true,
      path: PATH_ADMIN.version3.offerSales,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/offer-sales/Offer'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.addOfferSales,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/offer-sales/add-offers/AddOffer'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.offerSalesDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/offer-sales/details/OfferView'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.addOffersSalesDetail.addProduct,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/offer-sales/add-products/AddProduct'))
    },

    // OFFER - SALES MANAGER
    {
      exact: true,
      path: PATH_ADMIN.version3.offerSalesManager,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/offer-sales-manager/Offer'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.addOfferSalesManager,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/offer-sales-manager/add-offers/AddOffer'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.offerSalesManagerDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/offer-sales-manager/details/OfferView'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.addOfferSalesManagerDetail.addProduct,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/offer-sales-manager/add-products/AddProduct'))
    },

    // PRE ORDER - SALES
    {
      exact: true,
      path: PATH_ADMIN.version3.preOrderSales,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/preorder-sales/PreOrder'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.preOrderSalesDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/preorder-sales/PreOrderView'))
    },

    // PRE ORDER - SALES MANAGER
    {
      exact: true,
      path: PATH_ADMIN.version3.preOrderSalesManager,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/preorder-sales-manager/PreOrder'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.addPreOrderSalesManager,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/preorder-sales-manager/AddPreOrder'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.preOrderSalesManagerDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/preorder-sales-manager/PreOrderView'))
    },

    // PURCHASE ORDER - BUYER
    {
      exact: true,
      path: PATH_ADMIN.version3.purchaseOrderBuyer,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/purchase-order-buyer/PurchaseOrder'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.addPurchaseOrderBuyer,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/purchase-order-buyer/AddPurchaseOrder'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.purchaseOrderBuyerDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/purchase-order-buyer/PurchaseOrderView'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.purchaseOrderBuyerDetails.edit,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/purchase-order-buyer/PurchaseOrderEdit'))
    },

    // CLOSED OFFERS - BUYER
    {
      exact: true,
      path: PATH_ADMIN.version3.closedOffersBuyer,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/closed-offers-buyer/ClosedOffers'))
    },
    {
      exact: true,
      path: PATH_ADMIN.version3.closedOffersBuyerDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/closed-offers-buyer/ClosedOffersView'))
    },

    // SKU - BUYER
    {
      exact: true,
      path: PATH_ADMIN.version3.skuBuyer,
      component: lazy(() => import('src/views/AdminDashboardGroup/modules-version3/sku-buyer/SKU'))
    }
  ]
}

export default AppAdminRoutes