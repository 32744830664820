import { PATH_FINANCE } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardFinanceLayout from 'src/layouts/DashboardFinanceLayout';

// ----------------------------------------------------------------------

const AppFinanceRoutes = {
  path: PATH_FINANCE.root,
  guard: AuthProtect,
  layout: DashboardFinanceLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    // DASHBOARD
    {
      exact: true,
      path: PATH_FINANCE.general.dashboard,
      component: lazy(() => import('src/views/FinanceDashboardGroup/dashboard/DashboardAppView'))
    },

    // ORDER
    {
      exact: true,
      path: PATH_FINANCE.general.order,
      component: lazy(() => import('src/views/FinanceDashboardGroup/invoice/Invoice'))
    },
    {
      exact: true,
      path: PATH_FINANCE.general.orderDetails.view,
      component: lazy(() => import('src/views/FinanceDashboardGroup/invoice/invoice-details/EditInvoice'))
    },

    // PAYMENT
    {
      exact: true,
      path: PATH_FINANCE.general.payment,
      component: lazy(() => import('src/views/FinanceDashboardGroup/payment/Payment'))
    },
    {
      exact: true,
      path: PATH_FINANCE.general.paymentDetails.view,
      component: lazy(() => import('src/views/FinanceDashboardGroup/payment/PaymentView'))
    },

    // SHIPMENT
    {
      exact: true,
      path: PATH_FINANCE.general.shipment,
      component: lazy(() => import('src/views/FinanceDashboardGroup/shipment/Shipment'))
    },
    {
      exact: true,
      path: PATH_FINANCE.general.addShipment,
      component: lazy(() => import('src/views/FinanceDashboardGroup/shipment/AddShipment'))
    },
    {
      exact: true,
      path: PATH_FINANCE.general.shipmentDetails.view,
      component: lazy(() => import('src/views/FinanceDashboardGroup/shipment/details/ShipmentView'))
    },

    // REPORTS
    {
      exact: true,
      path: PATH_FINANCE.general.reports,
      component: lazy(() => import('src/views/FinanceDashboardGroup/report/Reports'))
    },

    // EMAIL
    {
      exact: true,
      path: PATH_FINANCE.general.email,
      component: lazy(() => import('src/views/FinanceDashboardGroup/email/EmailView'))
    }
  ]
}

export default AppFinanceRoutes
