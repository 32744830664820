import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import LazySize from 'src/components/LazySize';
import ProgressPlaceholder from 'src/images/progressPlaceholder.png';

// ----------------------------------------------------------------------

LogoAvatar.propTypes = {
  className: PropTypes.string
};

function LogoAvatar({ className, ...other }) {
  const { user } = useAuth();

  return (
    <LazySize
      src={user.companyLogo || ProgressPlaceholder}
      sx={{
        width: 40,
        height: 40,
        borderRadius: 1.5
      }}
    />
  );
}

export default LogoAvatar;
