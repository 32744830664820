import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { Icon } from '@iconify/react';
import { formatDistanceToNow } from 'date-fns';
import clockFill from '@iconify-icons/eva/clock-fill';
import { Link as RouterLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import useAuth from 'src/hooks/useAuth';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Avatar,
  ListItem,
  Typography,
  ListItemText,
  ListItemAvatar
} from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5, 2.5),
    '&:not(:last-child)': {
      marginBottom: 1
    }
  },
  isUnRead: {
    backgroundColor: theme.palette.action.selected
  }
}));

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.headerContent}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: 'text.secondary' }}
      >
        &nbsp; {noCase(notification.detailContent)}
      </Typography>
    </Typography>
  );

  if (notification.category === 'order_placed') {
    return {
      avatar: (
        <img
          alt={notification.category}
          src="/static/icons/ic_notification_package.svg"
        />
      ),
      title: title
    };
  }
  if (notification.category === 'order_shipped') {
    return {
      avatar: (
        <img
          alt={notification.category}
          src="/static/icons/ic_notification_shipping.svg"
        />
      ),
      title: title
    };
  }
  if (notification.category === 'mail') {
    return {
      avatar: (
        <img
          alt={notification.category}
          src="/static/icons/ic_notification_mail.svg"
        />
      ),
      title: title
    };
  }
  if (notification.category === 'payment') {
    return {
      avatar: (
        <img
          alt={notification.category}
          src="/static/icons/ic_notification_chat.svg"
        />
      ),
      title: title
    };
  }
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  className: PropTypes.string
};

function NotificationItem({ notification, className, setOpen, key }) {
  const classes = useStyles();
  const { user } = useAuth()
  //const avatar = null;
  //const item = renderContent(notification).headerContent;

  return (
    <ListItem
      button
      to={`/module/notifications/details/${notification._id}`}
      disableGutters
      key={key}
      component={RouterLink}
      className={clsx(
        classes.root,
        {
          [classes.isUnRead]: notification.recipientID.some((recipient) => recipient.userID === user._id && !recipient.isRead)
        },
        className
      )}
      onClick={() => {
        setOpen(false)
      }}
    >
      <ListItemAvatar>
        <img
          alt={notification.category}
          src="/static/icons/ic_notification_mail.svg"
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography variant="subtitle2">
              {ReactHtmlParser(notification.headerContent)}
            </Typography>
          </>
        }
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box
              component={Icon}
              icon={clockFill}
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {moment(notification.createdDate).format('DD/MM/YYYY hh:mm:ss A')}
          </Typography>
        }
      />
    </ListItem>
  );
}

export default NotificationItem;
