import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import _ from 'lodash';

import { 
  PATH_SALES, 
  PATH_ADMIN, 
  PATH_FINANCE ,
  PATH_LOGISTIC,
  PATH_ADMIN_KC,
  PATH_ADMIN_REGISTER,
  PATH_VERSION3,
  PATH_ACCOUNT_DISABLED
} from 'src/routes/paths';
import { Redirect } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

GuestProtect.propTypes = {
  children: PropTypes.node
};

function GuestProtect({ children }) {
  const { isLoading, isAuthenticated, user } = useAuth();

  console.log('GUEST: ', user)
  if (isLoading) {
    return <LoadingScreen />;
  }

  /**if (isAuthenticated && user?.roleID?.navigation_category === 'admin-distributor' && user?.distributorStatus === 'Approved') {
    return <Redirect to={PATH_ADMIN.general.root} />;
  }

  if (isAuthenticated && user?.roleID?.navigation_category === 'admin-distributor' && user?.distributorStatus === 'Pending') {
    return <Redirect to={PATH_ADMIN_REGISTER.general.root} />;
  }

  if (isAuthenticated && user?.roleID?.navigation_category === 'admin-distributor' && user?.distributorStatus === 'Submitted') {
    return <Redirect to={PATH_ADMIN_REGISTER.general.distributorSubmitted} />;
  }

  if (isAuthenticated && user?.roleID?.navigation_category === 'sales-distributor') {
    return <Redirect to={PATH_SALES.general.root} />;
  }

  if (isAuthenticated && user?.roleID?.navigation_category === 'finance-distributor') {
    return <Redirect to={PATH_FINANCE.general.root}/>;
  }
  
  if (isAuthenticated && user?.roleID?.navigation_category === 'logistic-distributor') {
    return <Redirect to={PATH_LOGISTIC.general.root}/>;
  }*/

  if (isAuthenticated && user.role === 'adminKC') {
    console.log('ROLE ADMIN KC')
    return <Redirect to={PATH_ADMIN_KC.general.root}/>;
  }
  
  // ALL NEW USER DISTRIBUTOR GO HERE
  if (isAuthenticated && user.navigationCategory !== 'old-admin-version-2' && user?.distributorStatus === 'Approved' && user?.status === 'enable') {
    console.log('ROLE NOT OLD ADMIN')
    if (!_.isEmpty(localStorage.getItem('preOrderDirect'))) {
      let preOrderPath = localStorage.getItem('preOrderDirect')
      let delimiter = '/'
      let start = 2
      let tokens = preOrderPath.split(delimiter).slice(start)
      let result = tokens.join(delimiter)

      let finalPreOrderPath = '/subdistributor/' + result

      localStorage.clear()
      return <Redirect to={finalPreOrderPath} />;
    }
    else {
      if (user.isFirstTimeLogin) {
        return <Redirect to={PATH_VERSION3.general.profile}/>;
      }
      else {
        return <Redirect to={PATH_VERSION3.general.root}/>;
      }
     
    }
  }

  if (isAuthenticated && user.navigationCategory === 'admin' && user?.distributorStatus === 'Pending' && user?.status === 'enable') {
    console.log('ROLE ADMIN PENDING')
    return <Redirect to={PATH_ADMIN_REGISTER.general.root} />;
  }

  if (isAuthenticated && user.navigationCategory === 'admin' && user?.distributorStatus === 'Rejected' && user?.status === 'enable') {
    console.log('ROLE ADMIN REJECTED')
    return <Redirect to={PATH_ADMIN_REGISTER.general.distributorRejected.detail} />;
  }

  if (isAuthenticated && user.navigationCategory === 'admin' && user?.distributorStatus === 'Submitted' && user?.status === 'enable') {
    console.log('ROLE ADMIN SUBMITTED')
    return <Redirect to={PATH_ADMIN_REGISTER.general.distributorSubmitted} />;
  }

  if (isAuthenticated && user?.status === 'disable') {
    return <Redirect to={PATH_ACCOUNT_DISABLED.general.root} />;
  }

  // OLD USER
  if (isAuthenticated && user.navigationCategory === 'old-admin-version-2' && user?.distributorStatus === 'Approved') {
    console.log('ROLE OLD ADMIN')
    return <Redirect to={PATH_ADMIN.general.root}/>;
  }

  return <>{children}</>;
}

export default GuestProtect;
