import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
const domainURL = process.env.REACT_APP_DOMAIN;

const initialState = {
  isLoading: false,
  listProducts: [],
  productType: '', // consignment or preorder
  editConsignmentSKUMode: false,
  isBackFromAddProduct: false // to track if user add new product in consignment sku order edit page
}

const slice = createSlice({
  name: 'consignmentSKUOrder',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setListProducts(state, action) {
      state.isLoading = false;
      state.listProducts = action.payload;
    },

    setProductType(state, action) {
      state.isLoading = false;
      state.productType = action.payload;
    },

    setEditConsignmentSKUMode(state, action) {
      state.isLoading = false;
      state.editConsignmentSKUMode = action.payload;
    },

    setIsBackFromAddProduct(state, action) {
      state.isLoading = false;
      state.isBackFromAddProduct = action.payload;
    },

    resetConsignmentSKUOrder(state) {
      state.listProducts = []
      state.productType = ''
      state.editConsignmentSKUMode = false
      state.isBackFromAddProduct = false
    }
  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
} = slice.actions;


// ----------------------------------------------------------------------
export function setListProducts(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setListProducts(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setProductType(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setProductType(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setEditConsignmentSKUMode(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setEditConsignmentSKUMode(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setIsBackFromAddProduct(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setIsBackFromAddProduct(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function resetConsignmentSKUOrder() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.resetConsignmentSKUOrder())
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}