import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_ADMIN, PATH_ADMIN_KC } from 'src/routes/paths';

import HomeIcon from '@material-ui/icons/Home';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';

import { Icon } from '@iconify/react';
import bellFill from '@iconify-icons/eva/bell-fill';
import bxHome from '@iconify/icons-bx/bx-home';
import shopIcon from '@iconify/icons-vs/shop';
import peopleGroup from '@iconify/icons-akar-icons/people-group';
import personIcon from '@iconify/icons-akar-icons/person';
import buildingWarehouse from '@iconify/icons-tabler/building-warehouse';
import currencyIcon from '@iconify/icons-carbon/currency';
// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  home: <Icon icon={bxHome} style={{ width: '2em', height: '2em'}}/>,
  notifications: <Icon icon={bellFill} style={{ width: '2em', height: '2em'}}/>,
  distributor: <Icon icon={buildingWarehouse} style={{ width: '2em', height: '2em'}}/>,
  retailer: <Icon icon={shopIcon} style={{ width: '2em', height: '2em'}}/>,
  user: <Icon icon={personIcon} style={{ width: '2em', height: '2em'}}/>,
  role: <Icon icon={peopleGroup} style={{ width: '2em', height: '2em'}}/>,
  currency: <Icon icon={currencyIcon} style={{ width: '2em', height: '2em'}}/>
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    //subheader: 'general',
    items: [
      {
        title: 'home',
        icon: ICONS.home,
        href: PATH_ADMIN_KC.general.root
      },
      {
        title: 'notifications',
        icon: ICONS.notifications,
        href: PATH_ADMIN_KC.general.notifications,
        badgeModule: 'notification'
      },
      {
        title: 'distributor',
        icon: ICONS.distributor,
        href: PATH_ADMIN_KC.general.distributor,
        badgeModule: 'distributorAdminKC'
      },
      {
        title: 'retailer',
        icon: ICONS.retailer,
        href: PATH_ADMIN_KC.general.retailer,
        badgeModule: 'retailerAdminKC'
      },
      {
        title: 'branch',
        icon: ICONS.retailer,
        href: PATH_ADMIN_KC.general.branch,
        badgeModule: 'branchAdminKC'
      },
      {
        title: 'user',
        icon: ICONS.user,
        href: PATH_ADMIN_KC.general.user
      },
      {
        title: 'role',
        icon: ICONS.role,
        href: PATH_ADMIN_KC.general.role
      },
      {
        title: 'currency',
        icon: ICONS.currency,
        href: PATH_ADMIN_KC.general.currency
      }
    ]
  }
];

export default navConfig;
