import 'firebase/auth';
import 'firebase/firestore';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { store } from 'src/redux/store';
import useAuth from 'src/hooks/useAuth';
import React, { useEffect } from 'react';
import { firebaseConfig } from 'src/config';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { useDispatch } from 'react-redux'
import { getUserDetail, logout } from 'src/redux/slices/authFirebase'
import routes, { renderRoutes } from 'src/routes'
import _ from 'lodash';

// ----------------------------------------------------------------------

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  //firebase.firestore();
}

const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: false
  },
  dispatch: store.dispatch,
  //createFirestoreInstance
};


// ----------------------------------------------------------------------

FirebaseProvider.propTypes = {
  children: PropTypes.node
};

function FirebaseProvider({ children }) {
  const { user, isAuthenticated } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleAuthChange = async (user) => {
      try {
        if (user && isAuthenticated) {
          let token = await user.getIdToken();
          dispatch(getUserDetail({ token }));
        } else if (_.isNull(user) && !isAuthenticated) {
          dispatch(logout());
        }
      } catch (error) {
        console.error(error);
      }
    }

    const unsubscribe = firebase.auth().onAuthStateChanged(handleAuthChange);

    return () => unsubscribe();
  }, [isAuthenticated, dispatch]);

  const renderUserRoutes = () => {
    if (user.status === 'disable') {
      return renderRoutes(routes.routesAccountDisabled);
    } else {
      if (user.role === 'adminKC') {
        return renderRoutes(routes.routesAdminKC);
      } else if (user.navigationCategory !== 'old-admin-version-2' &&
        user?.distributorStatus === 'Approved' &&
        user?.status === 'enable') {
        return renderRoutes(routes.routesAdminV3);
      } else if (user.navigationCategory === 'admin' &&
        (user?.distributorStatus === 'Pending' ||
          user?.distributorStatus === 'Submitted' ||
          user?.distributorStatus === 'Rejected') &&
        user?.status === 'enable') {
        return renderRoutes(routes.routesAdminRegister);
      } else if (user.navigationCategory === 'old-admin-version-2' &&
        user?.distributorStatus === 'Approved' &&
        user?.status === 'enable') {
        return renderRoutes(routes.routesAdmin);
      }
    }
    return renderRoutes(routes.routes);
  };

  return (
    <ReactReduxFirebaseProvider {...rrfProps}>
      {children}
      {renderUserRoutes()}
    </ReactReduxFirebaseProvider>
  );
}


export default FirebaseProvider;

/*

function FirebaseProvider({ children }) {
  const { user, isAuthenticated } = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    const getInitialize = async () => {
      try {
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user && isAuthenticated) {  
            let token = await user.getIdToken()

            //console.log('TOKEN: ', token)
            dispatch(getUserDetail({token: token}))
          }
          else if(_.isNull(user) && !isAuthenticated){
            dispatch(logout())
          }
        })
      } catch (error) {
        console.error(error)
      }
    }

    getInitialize();
  }, [isAuthenticated]);

  return (
    <ReactReduxFirebaseProvider {...rrfProps}>
      {children}
      {
        user.role === 'adminKC'
        ? renderRoutes(routes.routesAdminKC)
        :
        user.navigationCategory !== 'old-admin-version-2' && user?.distributorStatus === 'Approved' && user?.status === 'enable'
        ? renderRoutes(routes.routesAdminV3)
        :
        user.navigationCategory === 'admin' && (user?.distributorStatus === 'Pending' || user?.distributorStatus === 'Submitted' || user?.distributorStatus === 'Rejected') && user?.status === 'enable'
        ? renderRoutes(routes.routesAdminRegister)
        :
        user.navigationCategory === 'old-admin-version-2' && user?.distributorStatus === 'Approved' && user?.status === 'enable'
        ? renderRoutes(routes.routesAdmin)
        :
        user?.status === 'disable' 
        ? renderRoutes(routes.routesAccountDisabled)
        :
        renderRoutes(routes.routes)}
    </ReactReduxFirebaseProvider>
  );
}
*/
