import { PATH_ACCOUNT_DISABLED } from "./paths";
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardAccountDisabledLayout from 'src/layouts/DashboardAccountDisabledLayout';

// ----------------------------------------------------------------------

const AppAccountDisabledRoutes = {
  path: PATH_ACCOUNT_DISABLED.root,
  guard: AuthProtect,
  layout: DashboardAccountDisabledLayout,
  routes: [
    {
      exact: true,
      path: PATH_ACCOUNT_DISABLED.general.root,
      component: lazy(() => import('src/views/AccountDisabledGroup/home/Home.js')) 
    }
  ]
}

export default AppAccountDisabledRoutes