import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_ADMIN_REGISTER } from 'src/routes/paths';



import { Icon } from '@iconify/react';
import buildingWarehouse from '@iconify/icons-tabler/building-warehouse';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  distributor: <Icon icon={buildingWarehouse} style={{ width: '2em', height: '2em'}}/>
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general'
    /**items: [
      {
        title: 'distributor',
        icon: ICONS.distributor,
        href: PATH_ADMIN_REGISTER.general.distributor
      }
    ]*/
  }
];

export default navConfig;
