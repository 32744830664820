export const mapConfig = {
  apiGoogle: process.env.REACT_APP_MAP_GOOGLE,
  apiMapBox: process.env.REACT_APP_MAP_MAPBOX
};

/*export const firebaseConfig = {
  apiKey: "AIzaSyDBtRXzZHnAnuKlHH_mC_asDK1DiJKeenQ",
  authDomain: "betobee-930c2.firebaseapp.com",
  databaseURL: "https://betobee-930c2.firebaseio.com",
  projectId: "betobee-930c2",
  storageBucket: "betobee-930c2.appspot.com",
  messagingSenderId: "444507862708",
  appId: "1:444507862708:web:7f331ae11aae8183cf09e1"
}*/

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cloudinaryConfig = {
  cloudinaryKey: process.env.REACT_APP_CLOUDINARY_KEY,
  cloudinaryPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: process.env.REACT_APP_CLOUDINARY_URL
};

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const xgridConfig = {
  licenseKey: "b4af48f359478bf72ada226ebfacdccdT1JERVI6MjMzNzIsRVhQSVJZPTE2NDkzMTQ0MzcwMDAsS0VZVkVSU0lPTj0x"
}
