import { sum, map, filter, uniqBy, sample } from 'lodash';
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
const domainURL = process.env.REACT_APP_DOMAIN;

const initialState = {
  isLoading: false,
  priceName: '',
  priceOption: ''
}

const slice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setPriceName(state, action) {
      state.isLoading = false;
      state.priceName = action.payload;
    },

    setPriceOption(state, action) {
      state.isLoading = false;
      state.priceOption = action.payload;
    },

    resetPricing(state) {
      state.priceName = ''
      state.priceOption = ''
    }
  }
})

export default slice.reducer;

// Actions
export const {
} = slice.actions;


// ----------------------------------------------------------------------

export function setPriceName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setPriceName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setPriceOption(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setPriceOption(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}